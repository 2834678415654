import { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { isEmptyObject } from "@utils"

export const useTeam = (queryParam) => {
  const { departments } = useStaticQuery(query)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [department, setDepartment] = useState(departments.edges[currentIndex].node)

  useEffect(() => {
    if (isEmptyObject(queryParam)) {
      return
    }
    if(typeof queryParam.dep === "undefined") {
      return
    }
    setCurrentIndex(queryParam.dep)
  }, [queryParam])

  useEffect(() => {
    setDepartment(departments.edges[currentIndex].node)
  }, [currentIndex, departments])

  return {
    departments,
    department,
    currentIndex,
    setCurrentIndex,
    setDepartment,
  }
}

const query = graphql`
  query {
    departments: allWpDepartment(filter: { members: { nodes: { elemMatch: { id: { nin: ["cG9zdDo5MzE="] } } } } }) {
      edges {
        node {
          name
          slug
          members {
            nodes {
              id
              title
              slug
              profile {
                title
                duty
                love
                linkedin
                profile {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
                action {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(height: 708)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
